"use client";
import Icon from "@/components/primitives/icon";
import Button from "@/components/primitives/button";
import Card from "@/components/primitives/card";
import { HottestGame, Sport } from "common";
import PickGroup from "@/components/pick/group";
import OddsGroup from "../odds/group";
import Badge from "@/components/primitives/badge";
import { formatServerDate } from "@/utils";
import React from "react";
import { useWebSocketMessage } from "@/utils/useWebSocketMessage";
import { LoadingCircle } from "@/components/primitives/loading";
import NotFound from "@/components/primitives/not-found";
import { createGameDetailSlug } from "@/utils/url";
import useFetch from "@/utils/fetch";
import Timestamp from "../timestamp";
import GameCardSeriesChip from "./series-chip";

export default function GameCardHottestSkeleton() {
    return (
      <div className="relative animate-pulse"
      >
            <div className="absolute w-full left-0 right-0 -top-3 flex justify-center z-[4]">
                  <div className="flex items-center justify-center">
                  </div>
                </div>
        <div className="flex flex-col overflow-hidden bg-secondary-300 rounded-md card py-5 pb-7 px-6 w-full h-full relative z-[3]">

          <div className="flex justify-between items-center flex-wrap desktop-grid:flex-nowrap">
            <div className="whitespace-nowrap gap-1 uppercase text-dark items-center flex text-title-bold text-white pr-4 pb-3">
              <Icon name="flame" gradient="flame" size="sm"></Icon> Hottest Game
            </div>
            <div className="flex gap-2 text-label-sm-medium uppercase text-white pb-3">
              <div className="w-[80px] h-6 rounded-full bg-white animate-pulse"></div>
              <div className="w-[48px] h-6 rounded-full bg-secondary-400 animate-pulse"></div>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-headline-lg text-white uppercase flex items-center justify-center desktop-grid:justify-start gap-1">

              <div className="w-[89px] h-7 rounded-xl bg-surface-400 animate-pulse"></div>
              <div className="w-7 h-7 rounded-xl bg-surface-400 animate-pulse"></div>

              <div className="w-[132px] h-7 rounded-xl bg-surface-400 animate-pulse"></div>

            </div>
            <div className="flex justify-center items-center desktop-grid:justify-start">
              <div className="w-[200px] h-5 rounded-xl bg-surface-400 animate-pulse"></div>

            </div>
          </div>
          <div className="flex flex-col desktop-grid:flex-row gap-4 justify-center desktop-grid:justify-between items-center mt-3 desktop-grid:mt-0 desktop-grid:items-end">
            <div className="flex order-2 desktop-grid:order-1">
              <span className="text-label-lg text-white pb-1">
              </span>
            </div>
            <div className="flex order-1 desktop-grid:order-2 gap-2 items-center">
                <div className="w-[82px] h-8 bg-surface-400 rounded-md animate-pulse"></div>

            </div>
          </div>
          {/* FOOTER */}
          <div className="static mt-4 desktop-grid:mt-0 desktop-grid:absolute w-full max-w-[21rem] m-auto inset-0 flex justify-center items-center">
            <div
              className="p-[2px] rounded-md"
            >
                <div className="w-[329px] h-[94px] bg-secondary-100 animate-pulse rounded-xl"></div>
            </div>
          </div>
          <div className="pointer-events-none z-[-1] absolute top-[10%] w-[50%] h-full desktop-grid:w-[30rem] desktop-grid:h-[30rem] desktop-grid:-top-full left-0 desktop-grid:left-[10%] opacity-20 flex justify-center items-center">
            <div
              className="w-full h-full absolute"
              style={{
                background: `linear-gradient(270deg, rgba(35, 37, 40, 0) 30%, rgba(35, 37, 40, 1) 100%)`,
              }}
            ></div>
            {/* <img
              src={data.awayTeam.logoUrl}
              className="w-full h-full object-contain"
              alt={`${data.awayTeam.name} Logo Splash`}
            /> */}
          </div>
          <div className="pointer-events-none z-[-1] absolute top-[10%] w-[50%] h-full desktop-grid:w-[30rem] desktop-grid:h-[30rem] desktop-grid:-top-full right-0 desktop-grid:right-[10%] opacity-20 flex justify-center items-center">
          <div
                  className="w-full h-full absolute"
                  style={{
                    background: `linear-gradient(90deg, rgba(35, 37, 40, 0) 30%, rgba(35, 37, 40, 1) 100%)`,
                  }}
                ></div>
            {/* <img
              src={data.homeTeam.logoUrl}
              className="w-full h-full object-contain"
              alt={`${data.homeTeam.name} Logo Splash`}
            /> */}
          </div>
          {/* <Card.Trim
            fromColor={data.awayTeam.primaryColor}
            toColor={data.homeTeam.primaryColor}
          /> */}
        </div>
      </div>
    );

}
